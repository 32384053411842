import {
  ApolloClient,
  InMemoryCache,
  gql,
  HttpLink,
  ApolloLink,
  concat,
} from "@apollo/client";

const httpLink = new HttpLink({ uri: "https://cmf.onesi.pl/graphql" });
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("strapiToken");
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;

export const GET_CHALLENGES = gql`
  query Challenges {
    challenges {
      title
      slug
    }
  }
`;

export const GET_USER_ID = gql`
  query UserId($userId: String!) {
    challengeUsers(where: { userId: $userId }) {
      id
    }
  }
`;

export const GET_TASKS = gql`
  query Tasks($id: String!, $userId: ID!) {
    challenges(where: { slug: $id }) {
      id
      title
      startDate
      description
      tasks {
        title
        description
        dueTo
        slug
      }
    }
    challengeEntries(where: { challenge_user: { userId: $userId } }) {
      taskId
      created_at
    }
    challengeUsers(where: { userId: $userId }) {
      id
    }
  }
`;

export const GET_CHALLENGE_ENTRY = gql`
  query GetChallenge($userId: ID!, $challengeId: ID!, $taskId: ID!) {
    challengeEntries(
      where: {
        challenge_user: $userId
        challenge: $challengeId
        taskId: $taskId
      }
    ) {
      created_at
      comment
      files {
        name
        url
        size
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $userId: String!
    $username: String!
    $avatar: String!
    $email: String!
    $hashtag: String
  ) {
    createChallengeUser(
      input: {
        data: {
          userId: $userId
          username: $username
          avatar: $avatar
          email: $email
          hashtag: $hashtag
          site: "4"
        }
      }
    ) {
      challengeUser {
        userId
      }
    }
  }
`;

export const UPLOAD_ENTRY = gql`
  mutation CreateEntry(
    $userId: ID!
    $challengeId: ID!
    $taskId: String!
    $comment: String!
    $files: [ID]!
  ) {
    createChallengeEntry(
      input: {
        data: {
          challenge_user: $userId
          challenge: $challengeId
          taskId: $taskId
          comment: $comment
          site: "4"
          files: $files
        }
      }
    ) {
      challengeEntry {
        challenge {
          title
        }
      }
    }
  }
`;

export const GET_RESOURCES = gql`
  query getResources($tag: String, $category: String) {
    resources(
      where: {
        tags_contains: $tag
        category: $category
        type: "xpower_resources"
      }
    ) {
      id
      title
      description
      imageUrl
      url
      tags
      image {
        url
      }
    }
  }
`;

export const GET_RESOURCE = gql`
  query getResource($postId: ID!) {
    resource(id: $postId) {
      id
      title
      description
      imageUrl
      url
      tags
      category
      image {
        url
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    resources(where: { type: "xpower_resources" }) {
      category
    }
  }
`;
