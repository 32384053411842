import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Challenge from "../challenge/Challenge";
import Carousel from "@crm-package/crm-package/dist/Carousel";
import Metadata from "./Metadata";

export default function DashboardRoutes() {
  const { pathname } = useLocation();
  const history = useHistory();
  const challenges = useSelector((state: RootStateOrAny) => state.challenges);
  useEffect(() => {
    if (!challenges) return;
    if (pathname === "/challenges" && challenges[0]?.path)
      history.push(challenges[0]?.path);
  });
  return (
    <Switch>
      <Route
        path="/challenges"
        exact
        render={() => (
          <>
            <Metadata />
            <Carousel elements={challenges} />
          </>
        )}
      />
      <Route
        path={`/challenges/:id`}
        render={() => (
          <>
            <Metadata />
            <Challenge />
          </>
        )}
      />
      <Redirect to="/challenges" />
    </Switch>
  );
}
