import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Tags from "./Tags";
import Thumbnail from "./Thumbnail";
import { RootStateOrAny, useSelector } from "react-redux";

type SetString = React.Dispatch<React.SetStateAction<string>>;

type Props = {
  tag?: string;
  category?: string;
  setCategory?: SetString;
};

export default function ResourceList({ tag, category, setCategory }: Props) {
  const data = useSelector((state: RootStateOrAny) => state.resources).filter(
    (r: Resource) => {
      if (tag && !r.tags?.includes(tag)) return false;
      if (category && r.category !== category) return false;
      return true;
    }
  );

  useEffect(() => {
    if (!tag && !category && setCategory) setCategory("");
  }, [category, setCategory, tag]);

  const unique = (d: string[]) => Array.from(new Set(d));

  const tags: string[] = unique(data.map((el: Resource) => el.tags).flat());

  return (
    <>
      <h1 className="title has-text-black100">Resources</h1>
      <div className="mb-3">
        <Tags tags={tags} active={tag} />
      </div>
      <div className="columns is-multiline is-centered mt-3">
        {data.map((element: Resource) => (
          <div
            key={element.id}
            className="column is-4 text-decoration-none p-0"
          >
            <div className="m-3 has-background-white is-clipped has-border-radius">
              <Link to={"/resources/" + element.id}>
                <Thumbnail
                  imageUrl={element.imageUrl}
                  image={element.image?.url}
                />
              </Link>
              <div className="p-4">
                <Link to={"/resources/" + element.id}>
                  <h2 className="has-text-weight-bold has-text-black100">
                    {element.title}
                  </h2>
                </Link>
                {element.description && (
                  <div className="accordion-text mt-4">
                    <p className="accordion-text-content accordion-text-min-height has-text-weight-normal has-text-black80">
                      {element.description.replace(/<\/?[^>]+(>|$)/g, "")}
                    </p>
                  </div>
                )}
                {element.tags && (
                  <div className="mb-2 mt-4 tags-two-lines">
                    <Tags tags={element.tags} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
