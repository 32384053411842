import React from "react";
import Navbar from "@crm-package/crm-package/dist/Navbar";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { signOut } from "../redux/actions";
import logo from "../assets/logo.svg";

export default function Nav() {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const dispatch = useDispatch();
  return (
    <Navbar
      user={{
        name: user.username,
        avatar:
          user.id && user.avatar
            ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
            : undefined,
      }}
      userSignOut={() => dispatch(signOut())}
      noTrans
      logo={logo}
      className="has-background-white-mobile"
    />
  );
}
