import thunk from "redux-thunk";
import { allReducers } from "./reducers";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export const history = createBrowserHistory();

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));
const store = createStore(allReducers(history), composedEnhancer);

export default store;
