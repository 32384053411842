import React, { useEffect } from "react";
import { mdiMagnify } from "@mdi/js";
import Panel from "@crm-package/crm-package/dist/DashboardPanel";
import Logo from "../assets/logo.svg";
import LogoSmall from "../assets/logoSmall.svg";
import { GET_CHALLENGES } from "../graphql";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { setChallenges } from "../redux/actions";
import { useDispatch } from "react-redux";

type ChallengeType = {
  title: string;
  slug: string;
};

export default function DashboardPanel() {
  const { loading, error, data } = useQuery(GET_CHALLENGES);
  const history = useHistory();
  const dispatch = useDispatch();
  const routes = data
    ? data.challenges.map((challenge: ChallengeType) => {
        return {
          name: challenge.title,
          icon: mdiMagnify,
          path: `/challenges/${challenge.slug}`,
          display: true,
        };
      })
    : data;
  const categories = [{ name: "Challenges", routes }];

  useEffect(() => {
    if (error) history.goBack();
  }, [error, history]);

  useEffect(() => {
    if (routes) dispatch(setChallenges(routes));
  }, [dispatch, routes]);

  if (loading) return null;
  return (
    <Panel
      logo={Logo}
      logoClass="has-width-130"
      categories={categories}
      noTrans
      bg="has-background-white"
      prefix="challenges"
      shrinkable={{
        className:
          "p-3 is-flex is-justify-content-center is-align-items-center has-background-white is-clickable",
        logo: LogoSmall,
      }}
    />
  );
}
