import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useParams } from "react-router";
import BackToChallengeList from "../components/BackToChallengeList";
import LoadingPage from "../components/LoadingPage";
import Tags from "./Tags";
import Thumbnail from "./Thumbnail";

type SetString = React.Dispatch<React.SetStateAction<string>>;
type Props = { setCategory: SetString };

export default function Post({ setCategory }: Props) {
  const { id }: { id: string } = useParams();

  const post: Resource = useSelector(
    (state: RootStateOrAny) => state.resources
  ).find((el: Resource) => el.id === id);

  useEffect(() => {
    if (post?.category) setCategory(post?.category);
  }, [post, setCategory]);

  if (!post) return <LoadingPage />;
  return (
    <div className="columns is-centered">
      <div className="column is-8-desktop is-10-tablet is-12-mobile">
        <BackToChallengeList url="resources" text="BACK TO RESOURCES" />
        <div className="text-decoration-none">
          <div className="has-background-white is-clipped has-border-radius">
            <a href={post.url}>
              <Thumbnail imageUrl={post.imageUrl} image={post.image?.url} />
            </a>
            <div className="p-6">
              <a href={post.url}>
                <h2 className="is-size-4 has-text-weight-bold has-text-black100">
                  {post.title}
                </h2>
              </a>
              {post.tags && (
                <div className="my-2">
                  <Tags tags={post.tags} />
                </div>
              )}
              {post.description && (
                <div className="accordion-text mt-5">
                  <p
                    className="has-text-weight-normal has-text-black80 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: post.description,
                    }}
                  ></p>
                </div>
              )}
              <a href={post.url}>
                <button className="button is-primary mt-5">Show website</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
