import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/store";

const APP = (
  <Provider store={store}>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </ApolloProvider>
    </React.StrictMode>
  </Provider>
);

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
