import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import DashboardPanel from "./components/DashboardPanel";
import DashboardRoutes from "./components/DashboardRoutes";
import Dashboard from "@crm-package/crm-package/dist/Dashboard";
import "./scss/style.scss";
import Login from "./components/Login";
import Auth from "./components/Auth";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { initAuth } from "./redux/actions";
import AfterAuth from "./components/AfterAuth";
import Resources from "./resources/Resources";

export default function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const { pathname } = useLocation();
  useEffect(() => {
    if (!pathname.startsWith("/resources/") && pathname !== "/resources")
      dispatch(initAuth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/auth" component={Auth} />
      <Route path="/afterAuth" exact component={AfterAuth} />
      <Route path="/resources" component={Resources} />
      <Route path="/redirect" component={XpowerRedirect} />
      <Route
        path="/"
        render={() => (
          <>
            <Dashboard
              user={user}
              navbar={<Navbar />}
              dashboardPanel={<DashboardPanel />}
              dashboardRoutes={<DashboardRoutes />}
              noTrans
              bg="has-background-black20"
              defaultRoute="/redirect"
            />
          </>
        )}
      />
    </Switch>
  );
}

function XpowerRedirect() {
  useEffect(() => {
    window.location.href = "https://xpower.space/";
  }, []);
  return null;
}
