import React, { useEffect } from "react";
import logo from "../assets/logo.svg";
import discordLogo from "../assets/discord.svg";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Metadata from "./Metadata";

export default function Login() {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const history = useHistory();
  useEffect(() => {
    if (user?.id) history.push("/challenges");
  }, [history, user]);
  return (
    <div className="columns m-0">
      <Metadata />
      <div className="column is-12 height-100 is-relative bg-image m-0">
        <div className="columns is-centered height-100 is-vcentered m-0 is-flex">
          <div className="column is-4-fullhd is-5-widescreen is-6-desktop is-8-tablet is-12-mobile has-background-white p-6-tablet p-5-mobile mx-auto">
            <h1 className="is-size-3-tablet is-size-4-mobile has-text-centered has-text-black pt-6 has-text-weight-bold">
              Welcome in our challenge <br />
              Let's get started
            </h1>
            <figure className="has-text-centered my-6">
              <img src={logo} alt="" className="has-width-130" />
            </figure>

            <a
              href={`${process.env.REACT_APP_AUTH_URL}`}
              className="has-background-pink60 p-4 is-flex is-align-items-center is-justify-content-center border-radius-small"
            >
              <img src={discordLogo} alt="" />
              <p className="has-text-white ml-3 has-text-weight-bold">
                Log in with Discord
              </p>
            </a>
            <div className="pb-6" />
          </div>
        </div>
      </div>
    </div>
  );
}
