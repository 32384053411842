import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { authUser } from "../redux/actions";
import { useDispatch } from "react-redux";

export default function Auth() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const code = params.get("code") || "noCode";
      dispatch(authUser(code));
    }
  }, [dispatch, location.search]);
  return <div></div>;
}
