import React, { useEffect, useState } from "react";

type Props = { image: string; imageUrl: string };

export default function Thumbnail({ image, imageUrl }: Props) {
  const [validSrc, setValidSrc] = useState<string | null>(null);
  useEffect(() => {
    const checkSrc = (src: string, next?: string) => {
      const testImg = new Image();
      testImg.onload = () => setValidSrc(src);
      testImg.onerror = () => (next ? checkSrc(next) : null);
      testImg.src = src;
    };
    if (image || imageUrl)
      checkSrc(process.env.REACT_APP_STRAPI_URL + image, imageUrl);
  }, [image, imageUrl]);
  return (
    <figure className="image is-5by3">
      <img
        src={validSrc || "/ogimage.png"}
        alt=""
        className="object-fit-contain"
        onError={(e) => ((e.target as HTMLImageElement).src = "/ogimage.png")}
      />
    </figure>
  );
}
