import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

type Props = {
  categories: string[];
  category: string;
};

export default function Navbar({ categories, category }: Props) {
  const [menu, setMenu] = useState(false);

  const showMenu = () => setMenu(!menu);
  return (
    <nav
      className="navbar has-background-transparent py-3 mb-4"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/resources" className="navbar-item">
          <img
            src={logo}
            alt=""
            style={{ height: "40px", maxHeight: "40px" }}
          />
        </Link>
        <button
          className={`navbar-burger ${menu && "is-active"}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={showMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div className={`navbar-menu ${menu && "is-active"}`}>
        <div className="navbar-start" />
        <div className="navbar-end">
          {categories.map((el) => (
            <Link
              key={el}
              to={`/resources/category/${el}`}
              className={`navbar-item has-text-black100 has-text-weight-medium has-background-transparent hover-black ${
                el === category && "is-underlined"
              }`}
            >
              {el}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}
