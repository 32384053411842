import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

type Action = { type: string; payload: any };

const user = (state: null | User = null, action: Action) => {
  switch (action.type) {
    case "SET_USER":
      if (state && state.token !== "noUser")
        return { ...state, ...action.payload };
      return action.payload;
    case "SET_TOKEN":
      if (state && state.token !== "noUser")
        return { ...state, token: action.payload };
      return { token: action.payload };
    case "RESET_USER":
      return { token: "noUser" };
    default:
      return state;
  }
};

type Challenge = { name: string; icon: string; path: string; display: boolean };

const challenges = (state: Challenge[] = [], action: Action) => {
  switch (action.type) {
    case "SET_CHALLENGES":
      return action.payload;
    default:
      return state;
  }
};

const resources = (state: Resource[] = [], action: Action) => {
  switch (action.type) {
    case "SET_RESOURCES":
      return action.payload;
    default:
      return state;
  }
};

const strapi = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case "SET_STRAPI_TOKEN":
      return action.payload;
    default:
      return state;
  }
};

const allReducers = (history: History<unknown>) =>
  combineReducers({
    user,
    challenges,
    resources,
    strapi,
    router: connectRouter(history),
  });

export { allReducers };
