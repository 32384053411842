import { useMutation } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CREATE_USER } from "../graphql";

export default function AfterAuth() {
  const history = useHistory();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [addUser] = useMutation(CREATE_USER, {
    variables: {
      userId: user?.id,
      username: user?.username,
      avatar: user?.avatar,
      email: user?.email,
      hashtag: user?.discriminator,
    },
  });

  useEffect(() => {
    if (user?.id)
      addUser()
        .then(() => history.push("/challenges"))
        .catch((err) => {
          if (err.message === "Duplicate entry") history.push("/challenges");
        });
  }, [addUser, history, user]);
  return <div></div>;
}
