import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ResourceList from "./ResourceList";

type SetString = React.Dispatch<React.SetStateAction<string>>;
type Props = { setCategory: SetString };

export default function Categories({ setCategory }: Props) {
  const { id }: { id: string } = useParams();

  useEffect(() => {
    if (id) setCategory(id);
  }, [id, setCategory]);

  return <ResourceList category={id} />;
}
