import React, { useEffect } from "react";

type Props = {
  date?: string;
  title: string;
};

export default function Waiting({ date, title }: Props) {
  useEffect(() => {
    const el = document.querySelector(".challenge-bg");
    if (date === undefined) el?.classList.add("is-hidden");

    return () => {
      if (date === undefined) el?.classList.remove("is-hidden");
    };
  }, [date]);
  return (
    <div
      className={`column is-12 waiting-height ${
        date ? "waiting-bg-date" : "waiting-bg-no-date"
      }`}
    >
      <div className="columns">
        <div className="column is-offset-1 is-8">
          <h1 className="is-size-1 has-text-black100 has-text-weight-bold mt-5 is-size-4-mobile ml-4">
            {title}
          </h1>
          {date ? (
            <p className="has-text-weight-bold has-text-black60 is-size-6 is-size-7-mobile mt-4 ml-4">
              This challenge will begin on{" "}
              <span className="has-text-primary ml-1">{date}</span>
            </p>
          ) : (
            <p className="has-text-weight-bold has-text-black60 is-size-6 is-size-7-mobile mt-4 ml-4">
              This challenge hasn't started, yet. <br />
              Expect information on your e-mail
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
