import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import LoadingPage from "../components/LoadingPage";
import { GET_CATEGORIES, GET_RESOURCES } from "../graphql";
import {
  initStrapiToken,
  setResources,
  setStrapiToken,
} from "../redux/actions";
import Categories from "./Categories";
import Footer from "./Footer";
import Post from "./Post";
import ResourceList from "./ResourceList";
import ResourcesNav from "./ResourcesNav";
import TagList from "./TagList";
import { isTokenValid } from "./helpers";

export default function Resources() {
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [cachedResources, setCachedResources] = useState(null);
  const [cachedCategories, setCachedCategories] = useState<string[]>([]);
  const [getCategoriesData, categoriesData] = useLazyQuery(GET_CATEGORIES);
  const [getResourcesData, resourcesData] = useLazyQuery(GET_RESOURCES);
  const strapiToken = useSelector((state: RootStateOrAny) => state.strapi);
  const dispatch = useDispatch();

  const unique = (d: string[]) => Array.from(new Set(d));

  useEffect(() => {
    const token = localStorage.getItem("strapiToken");
    if (token) {
      if (isTokenValid(token) === false) dispatch(initStrapiToken());
      else dispatch(setStrapiToken(token));
    } else dispatch(initStrapiToken());
  }, [dispatch]);

  useEffect(() => {
    if (strapiToken) {
      if (navigator.userAgent === "ReactSnap") {
        const resources = localStorage.getItem("resources");
        const categories = localStorage.getItem("categories");
        if (!resources) getResourcesData();
        else setCachedResources(JSON.parse(resources));
        if (!categories) getCategoriesData();
        else setCachedCategories(JSON.parse(categories));
      } else {
        getCategoriesData();
        getResourcesData();
      }
    }
  }, [strapiToken, getResourcesData, getCategoriesData]);

  useEffect(() => {
    if (categoriesData.data) {
      const categories = categoriesData.data?.resources
        ? unique(
            categoriesData.data.resources.map((el: Resource) => el.category)
          )
        : [];
      setCachedCategories(categories);
      if (navigator.userAgent === "ReactSnap")
        localStorage.setItem("categories", JSON.stringify(categories));
    }
  }, [categoriesData.data]);

  useEffect(() => {
    if (cachedResources) {
      dispatch(setResources(cachedResources));
      setLoading(false);
    }
  }, [dispatch, cachedResources]);

  useEffect(() => {
    if (resourcesData.data) {
      dispatch(setResources(resourcesData.data.resources));
      setLoading(false);
      if (navigator.userAgent === "ReactSnap")
        localStorage.setItem(
          "resources",
          JSON.stringify(resourcesData.data.resources)
        );
    }
  }, [resourcesData.data, dispatch]);

  if (loading) return <LoadingPage />;
  return (
    <div className="overflow-y-auto height-100 bg-image2">
      <Metadata />
      <div className="is-flex is-flex-1 min-height-100">
        <div className="container px-4">
          <ResourcesNav categories={cachedCategories} category={category} />
          <Switch>
            <Route
              path="/resources"
              exact
              render={() => <ResourceList setCategory={setCategory} />}
            />
            <Route
              path="/resources/category/:id"
              exact
              render={() => <Categories setCategory={setCategory} />}
            />
            <Route
              path="/resources/tags/:tag"
              exact
              render={() => <TagList setCategory={setCategory} />}
            />
            <Route
              path="/resources/:id"
              exact
              render={() => <Post setCategory={setCategory} />}
            />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function Metadata() {
  return (
    <Helmet>
      <title>Resources to explore UX world from xpower</title>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://panel.xpower.space/resources" />
      <meta
        property="og:title"
        content="Resources to explore UX world from xpower"
      />
      <meta
        property="og:description"
        content="Get to know more about UX and stay updated with industry happenings. Use our database of links to various sources and materials related to areas such as UI / UX design, UX research, UX writing, Project Management."
      />
      <meta
        property="og:image"
        content={`https://panel.xpower.space/ogimageresources.png`}
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content="https://panel.xpower.space/resources"
      />
      <meta
        property="twitter:title"
        content="Resources to explore UX world from xpower"
      />
      <meta
        property="twitter:description"
        content="Get to know more about UX and stay updated with industry happenings. Use our database of links to various sources and materials related to areas such as UI / UX design, UX research, UX writing, Project Management."
      />
      <meta
        property="twitter:image"
        content={`https://panel.xpower.space/ogimageresources.png`}
      />
    </Helmet>
  );
}
