import React, { useEffect } from "react";
import { useParams } from "react-router";
import ResourceList from "./ResourceList";

type SetString = React.Dispatch<React.SetStateAction<string>>;
type Props = { setCategory: SetString };

export default function TagList({ setCategory }: Props) {
  const { tag }: { tag: string } = useParams();

  useEffect(() => {
    setCategory("");
  }, [setCategory]);
  return <ResourceList tag={tag} />;
}
