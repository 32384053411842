import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  id?: string;
  url?: string;
  text?: string;
};

export default function BackToChallengeList({ id, url, text }: Props) {
  return (
    <Link to={`/${url || "challenges"}${id ? "/" + id : ""}`}>
      <div className="is-inline-flex mb-3 has-text-black80 go-back-hover">
        <figure className="image is-24x24 ml-minus">
          <Icon path={mdiChevronLeft} />
        </figure>
        <p>{text || "BACK TO CHALLENGE LIST"}</p>
      </div>
    </Link>
  );
}
