import axios from "axios";

export const verifyCode = (code: string) => {
  return new Promise((response, reject) => {
    const data = new URLSearchParams();
    data.append("client_id", process.env.REACT_APP_CLIENT_ID || "");
    data.append("client_secret", process.env.REACT_APP_CLIENT_SECRET || "");
    data.append("grant_type", "authorization_code");
    data.append("code", code);
    data.append("redirect_uri", process.env.REACT_APP_REDIRECT_URL || "");
    axios
      .post(`${process.env.REACT_APP_AUTH_CODE}`, data, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => response(res.data))
      .catch((err) => reject(err));
  });
};

export const getMyData = (type: string, token: string) => {
  return new Promise((response, reject) => {
    axios
      .get("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${type} ${token}`,
        },
      })
      .then((res) => response(res.data))
      .catch((err) => reject(err));
  });
};

export const refreshToken = (token: string) => {
  return new Promise((response, reject) => {
    const data = new URLSearchParams();
    data.append("client_id", process.env.REACT_APP_CLIENT_ID || "");
    data.append("client_secret", process.env.REACT_APP_CLIENT_SECRET || "");
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", token);
    axios
      .post("https://discord.com/api/v8/oauth2/token", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => response(res.data))
      .catch((err) => reject(err));
  });
};

export const uploadImage = (file: File[]) => {
  return new Promise((response, reject) => {
    const data = new FormData();
    file.forEach((f) => {
      data.append(`files`, f, f.name);
    });

    axios
      .post("https://cmf.onesi.pl/upload", data)
      .then((res) => response(res.data))
      .catch((err) => reject(err));
  });
};

type StrapiToken = {
  jwt: string;
  user: {
    id: number;
    username: string;
    email: string;
    provider: string;
    confirmed: boolean;
    blocked: boolean;
    role: {
      id: number;
      name: string;
      description: string;
      type: string;
    };
    created_at: string;
    updated_at: string;
  };
};

export const getStrapiToken = (): Promise<StrapiToken> => {
  return new Promise((response, reject) => {
    axios
      .post("https://cmf.onesi.pl/auth/local", {
        identifier: process.env.REACT_APP_STRAPI_LOGIN,
        password: process.env.REACT_APP_STRAPI_PASSWORD,
      })
      .then((res) => response(res.data))
      .catch((err) => reject(err));
  });
};
