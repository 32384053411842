import React from "react";
import { Link } from "react-router-dom";

type Props = {
  active?: string;
  tags: string[];
};

export default function Tags({ tags, active }: Props) {
  return (
    <>
      {tags.map((tag, i) => (
        <Link
          to={tag === active ? "/resources" : `/resources/tags/${tag}`}
          className="has-text-black100"
          key={tag + i}
          style={{ display: "contents" }}
        >
          <span
            className={`tag mr-2 mb-2 is-tag-size ${
              active === tag && "is-primary"
            }`}
          >
            {tag}
          </span>
        </Link>
      ))}
    </>
  );
}
