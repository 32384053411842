import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { GET_CHALLENGE_ENTRY } from "../graphql";
import AccordionText from "@crm-package/crm-package/dist/AccordionText";
import Menu, { MenuEl } from "@crm-package/crm-package/dist/Menu";
import moment from "moment";
import { useEffect } from "react";
import BackToChallengeList from "./BackToChallengeList";

type SummaryType = {
  task: MenuEl;
  challengeId: number;
  userId: number;
};

export default function Summary({ task, challengeId, userId }: SummaryType) {
  const { id, taskId }: { id: string; taskId: string } = useParams();
  const { error, data } = useQuery(GET_CHALLENGE_ENTRY, {
    variables: { userId, challengeId, taskId },
  });

  const history = useHistory();
  const elements = data
    ? data.challengeEntries[0].files.map(
        (el: { url: string; name: string; size: number }) => {
          return {
            isImage: `${process.env.REACT_APP_STRAPI_URL}${el.url}`,
            title: el.name,
            size: `${Math.round(el.size || 0)}KB`,
          };
        }
      )
    : [];

  useEffect(() => {
    if (error) history.goBack();
  }, [error, history]);

  useEffect(() => {
    document.querySelector(".dashboard")?.scrollTo(0, 0);
  }, []);

  if (!data) return null;
  if (elements === undefined) return null;
  return (
    <div className="columns m-0">
      <div className="column is-8-widescreen is-9-desktop is-11-tablet is-offset-1">
        <div className="container">
          <BackToChallengeList id={id} />
          <h1 className="is-size-1 is-size-2-mobile has-text-weight-semibold has-text-green my-4">
            {task.title}{" "}
            <span className="is-size-6 ml-5 is-size-7-mobile">
              ADDED{" "}
              {data?.challengeEntries[0].created_at &&
                moment(data?.challengeEntries[0].created_at).format(
                  "DD MMMM YYYY"
                )}
            </span>
          </h1>
          <AccordionText lines={3} text={task.desc || ""} />
          <div className="mt-6">
            <label className="label">Images</label>
            <Menu columns={3} elements={elements} />
          </div>
          <div className="py-6">
            <label className="label">Comment</label>
            <p>{data?.challengeEntries[0].comment}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
