import React from "react";
import Loading from "@crm-package/crm-package/dist/Loading";

export default function LoadingPage() {
  return (
    <div className="height-100 is-flex is-justify-content-center is-align-items-center">
      <Loading noTrans />
    </div>
  );
}
