import jwtDecode from "jwt-decode";
import moment from "moment";

export const isTokenValid = (token: string) => {
  const jwt: { exp: number } = jwtDecode(token);
  const now = moment();
  const exp = moment(jwt.exp * 1000);
  if (now.diff(exp) >= 0) return false;
  return true;
};
