import React from "react";
import { Helmet } from "react-helmet";

export default function Metadata() {
  return (
    <Helmet>
      <title>Submit xPower UX challenge responses here</title>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://panel.xpower.space/" />
      <meta
        property="og:title"
        content="Submit xPower UX challenge responses here"
      />
      <meta
        property="og:description"
        content="This page is a workspace that allows you to submit responses to xPower's UX challenges"
      />
      <meta
        property="og:image"
        content={`https://panel.xpower.space/ogimage.png`}
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://panel.xpower.space/" />
      <meta
        property="twitter:title"
        content="Submit xPower UX challenge responses here"
      />
      <meta
        property="twitter:description"
        content="This page is a workspace that allows you to submit responses to xPower's UX challenges"
      />
      <meta
        property="twitter:image"
        content={`https://panel.xpower.space/ogimage.png`}
      />
    </Helmet>
  );
}
