import React from "react";
import logo from "../assets/logo.svg";
import fblogo from "../assets/logo-facebook.svg";
import linkedinlogo from "../assets/icon-linkedin.svg";

export default function Footer() {
  return (
    <div className="footer mt-6 has-text-black100">
      <div className="container pt-1">
        <div className="mx-3">
          <div className="columns mb-4">
            <div className="column is-flex is-justify-content-center-mobile">
              <img src={logo} alt="" className="has-width-150" />
            </div>
            <div className="column has-text-centered is-justify-content-center">
              <p>
                <a href="https://e-ux.pro/">E-ux.pro</a> LTD
              </p>
              <p>
                <a href="mailto:contact@xpower.space">contact@xpower.space</a>
              </p>
            </div>
            <div className="column is-justify-content-flex-end-tablet is-justify-content-center-mobile is-flex">
              <a
                href="https://www.facebook.com/xpower-104947668589814/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <figure className="image is-40x40 has-border border-color-normal p-2 is-round has-background-transparent is-faded">
                  <img src={fblogo} alt="" />
                </figure>
              </a>
              <a
                href="https://www.linkedin.com/company/xpower-community/"
                target="_blank"
                className="ml-5 "
                rel="noopener noreferrer"
              >
                <figure className="image is-40x40 has-border border-color-normal p-2 is-round has-background-transparent is-faded">
                  <img src={linkedinlogo} alt="" />
                </figure>
              </a>
            </div>
          </div>
          <p className="is-size-7 mb-4">
            Important: we use cookies. We use information saved by cookies and
            similar technologies, incl. for advertising, statistical purposes,
            and to adapt our services to the individual needs of users.
          </p>
          <div className="columns mt-4">
            <div className="column is-8 is-hidden-mobile">
              <p>
                Copyright © 2021 <a href="https://e-ux.pro/">E-ux.pro</a> . All
                Rights Reserved
              </p>
            </div>
            <div className="column is-2 is-flex is-justify-content-flex-end-tablet is-justify-content-center-mobile">
              <a
                href="https://xpower.space/documents/Privacy-Policy-of-Service--XPOWER.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="has-text-black100 is-size-7-mobile"
              >
                Privacy policy
              </a>
            </div>
            <div className="column is-2 is-flex is-justify-content-flex-end-tablet is-justify-content-center-mobile">
              <a
                href="https://xpower.space/documents/Terms-and-Conditions-XPOWER.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="has-text-black100 is-size-7-mobile"
              >
                Statute
              </a>
            </div>
            <div className="column is-8 is-hidden-tablet">
              <p className="is-size-7-mobile">
                <span className="has-text-weight-light">Copyright © 2021</span>{" "}
                <a href="https://e-ux.pro/">E-ux.pro</a> . All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
