import { getMyData, getStrapiToken, refreshToken, verifyCode } from "../axios";
import { history } from "./store";

export const authUser = (code: string) => {
  return async (dispatch: any) => {
    const data = (await verifyCode(code)) as AuthTokenType;
    if (!data) return history.push("/login");
    dispatch(setToken(data.refresh_token));
    localStorage.setItem("refreshToken", data.refresh_token);
    const myData = (await getMyData(
      data.token_type,
      data.access_token
    )) as User;
    if (myData) {
      dispatch(setUser(myData));
      history.push("/afterAuth");
    } else history.push("/login");
  };
};

export const initAuth = () => {
  return async (dispatch: any) => {
    const strapiToken = await getStrapiToken();
    localStorage.setItem("strapiToken", strapiToken.jwt);
    const token = localStorage.getItem("refreshToken");
    if (!token) {
      dispatch(resetUser());
      return;
    }
    refreshToken(token)
      .then((res) => {
        const data = res as AuthTokenType;
        dispatch(setToken(data.refresh_token));
        localStorage.setItem("refreshToken", data.refresh_token);
        getMyData(data.token_type, data.access_token)
          .then((res) => {
            const myData = res as User;
            dispatch(setUser(myData));
            history.push("/challenges");
          })
          .catch(() => {
            localStorage.removeItem("refreshToken");
            history.push("/login");
          });
      })
      .catch(() => {
        localStorage.removeItem("refreshToken");
        dispatch(resetUser());
      });
  };
};

export const initStrapiToken = () => {
  return async (dispatch: any) => {
    const token = await getStrapiToken();
    localStorage.setItem("strapiToken", token.jwt);
    dispatch(setStrapiToken(token.jwt));
  };
};

const resetUser = () => {
  return {
    type: "RESET_USER",
  };
};

const setUser = (data: User) => {
  return {
    type: "SET_USER",
    payload: data,
  };
};

const setToken = (data: string) => {
  return {
    type: "SET_TOKEN",
    payload: data,
  };
};

export const setStrapiToken = (data: string) => {
  return {
    type: "SET_STRAPI_TOKEN",
    payload: data,
  };
};

export const signOut = () => {
  localStorage.removeItem("refreshToken");
  return {
    type: "RESET_USER",
  };
};

export const setChallenges = (data: Challenge) => {
  return {
    type: "SET_CHALLENGES",
    payload: data,
  };
};

export const setResources = (data: Resource[]) => {
  return {
    type: "SET_RESOURCES",
    payload: data,
  };
};
