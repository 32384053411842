import { mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useRef, useState } from "react";
import FileSent from "@crm-package/crm-package/dist/FileSent";
import ActionButton from "@crm-package/crm-package/dist/ActionButton";
import AccordionText from "@crm-package/crm-package/dist/AccordionText";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPLOAD_ENTRY } from "../graphql";
import { useEffect } from "react";
import { uploadImage } from "../axios";
import { MenuEl } from "@crm-package/crm-package/dist/Menu";
import BackToChallengeList from "./BackToChallengeList";
import { signOut } from "../redux/actions";
import { useDispatch } from "react-redux";

type FileResponse = {
  name: string;
  size?: number;
  displaySize?: string;
  id?: string;
  progress?: number;
};

type TaskType = {
  task: MenuEl;
  challengeId: number;
  userId: number;
  updateUploaded: (id: string) => void;
};

export default function Task({
  task,
  challengeId,
  userId,
  updateUploaded,
}: TaskType) {
  const [file, setFile] = useState<File[]>([]);
  const [sentFiles, setSendFiles] = useState<FileResponse[]>([]);
  const [comment, setComment] = useState("");
  const { id, taskId }: { id: string; taskId: string } = useParams();
  const dispatch = useDispatch();
  const [createEntry, { error }] = useMutation(UPLOAD_ENTRY, {
    variables: {
      userId,
      challengeId,
      taskId,
      comment,
      files: sentFiles.map((f) => f.id),
    },
    onError: (err) => {
      dispatch(signOut());
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const changeFile = (files: FileList | null) => {
    if (!files) return;
    const fileArray: File[] = [];
    Array.from(files).forEach((file) => fileArray.push(file));
    setFile(fileArray);
  };

  const removeFile = (name: string) => {
    setSendFiles((files) => files.filter((f) => f.name !== name));
  };

  const dropFile = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      const fileArray: File[] = [];
      Array.from(event.dataTransfer.items).forEach((file) => {
        if (file.kind === "file") {
          const f = file.getAsFile();
          if (f) {
            fileArray.push(f);
            setSendFiles((sf) => [...sf, { name: f.name, progress: 0 }]);
          }
        }
      });
      setFile(fileArray);
    }
  };

  const clickInput = () => {
    fileInputRef.current?.click();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dropzoneRef.current?.classList.add("is-active");
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dropzoneRef.current?.classList.remove("is-active");
  };

  const updateComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    const btn = document.getElementById("sendBtn") as HTMLButtonElement;
    if (e.target.value) {
      btn.disabled = false;
      btn.onclick = uploadEntry;
    } else btn.disabled = true;
  };

  const uploadEntry = () => {
    createEntry().then(() => {
      updateUploaded(taskId);
      history.goBack();
    });
  };

  useEffect(() => {
    document.querySelector(".dashboard")?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (file.length > 0) {
      uploadImage(file)
        .then((res) => {
          setSendFiles((f) =>
            f.map((fileToMap) => {
              const data: FileResponse[] = res as FileResponse[];
              const found = data.find((el) => el.name === fileToMap.name);
              if (found)
                return {
                  name: found.name,
                  displaySize: `${Math.round(found.size || 0)}KB`,
                  id: found.id,
                };
              return fileToMap;
            })
          );
        })
        .catch((err) => console.log(err));
      const fileArray: FileResponse[] = [];
      file.forEach((f) => fileArray.push({ name: f.name, progress: 0 }));
      setSendFiles((f) => [...f, ...fileArray]);
    }
  }, [file]);

  useEffect(() => {
    if (error) history.goBack();
  }, [error, history]);

  return (
    <div className="columns m-0">
      <div
        className="column is-7-widescreen is-9-desktop is-11-tablet is-offset-1 is-offset-1 overflow-y-auto pb-6"
        ref={scrollRef}
      >
        <div className=" mt-2 mb-1">
          <BackToChallengeList id={id} />
        </div>
        <h1 className="is-size-1-tablet is-size-3-mobile has-text-black100 has-text-weight-bold mb-2">
          {task.title}
        </h1>
        <AccordionText lines={3} text={task.desc || ""} />
        {!task.inactive && (
          <>
            <input
              className="input is-hidden"
              type="file"
              multiple={true}
              onChange={(e) => changeFile(e.target.files)}
              data-trans="username"
              ref={fileInputRef}
            />
            <div className="columns" data-trans="Images">
              <div className="column is-12">
                <label className="label mt-6 has-text-weight-medium">
                  Images
                </label>
                <div
                  className="is-flex is-flex-direction-column has-border-upload py-6 border-radius-small has-background-white"
                  onDrop={dropFile}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  ref={dropzoneRef}
                >
                  <figure className="image is-48x48 mx-auto mb-3">
                    <Icon path={mdiUpload} color="#0026f3" />
                  </figure>
                  <p className="mx-auto has-text-weight-medium">
                    Drop your images here or{" "}
                    <span
                      className="has-text-blue80 is-clickable link-back-hover"
                      onClick={clickInput}
                    >
                      browse
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <FileSent files={sentFiles} del={removeFile} spinner />
            <label className="label mt-6 has-text-weight-medium">
              Comment*
            </label>
            <textarea
              name="comment"
              className="textarea has-text-black100"
              value={comment}
              onChange={updateComment}
              data-trans="Comment*"
              placeholder="Type text"
            />
            <p className="has-text-black100 mt-5">
              After submitting your answer, you will not be able to edit it
              anymore.
            </p>
            <div className="columns mx-0 mt-5">
              <ActionButton
                id="sendBtn"
                className="column is-5-tablet is-12-mobile is-primary py-0"
                text="Send"
                action={uploadEntry}
                disabled
                noTrans
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
