import React, { useCallback, useEffect, useState } from "react";
import Menu, { MenuEl } from "@crm-package/crm-package/dist/Menu";
import { mdiArrowRight } from "@mdi/js";
import AccordionText from "@crm-package/crm-package/dist/AccordionText";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { GET_TASKS } from "../graphql";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Summary from "../components/Summary";
import Task from "../components/Task";
import Carousel from "@crm-package/crm-package/dist/Carousel";
import { signOut } from "../redux/actions";
import { Helmet } from "react-helmet";
import bgPattern from "../assets/bgPattern.svg";
import Waiting from "../components/Waiting";

type TaskType = {
  title: string;
  description: string;
  dueTo: string;
  slug: string;
  startDate: string;
};

type ProcessedTask = {
  title: string;
  desc: string;
  inactive: boolean;
  completed: boolean;
  url: string;
  icon: string;
};

type ChallengeType = {
  id: number;
  title: string;
  desc: string;
  starts?: moment.Moment;
};

export default function Challenge() {
  const [tasks, setTasks] = useState<ProcessedTask[]>([]);
  const [challenge, setChallenge] = useState<ChallengeType>();
  const [userId, setUserId] = useState(0);
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const challenges = useSelector((state: RootStateOrAny) => state.challenges);
  const [getTasks, { data, error }] = useLazyQuery(GET_TASKS, {
    variables: { id, userId: user?.id },
  });

  const updateUploaded = (id: string) => {
    setTasks((t) =>
      t.map((task) => {
        if (task.url === id)
          return {
            ...task,
            completed: true,
          };
        return task;
      })
    );
  };

  const logOut = useCallback(() => dispatch(signOut()), [dispatch]);

  useEffect(() => {
    if (error) logOut();
  }, [dispatch, error, logOut]);

  useEffect(() => {
    if (user?.id) getTasks();
  }, [getTasks, user]);

  useEffect(() => {
    if (data && !error) {
      const challenge = data.challenges[0];
      const uId = data.challengeUsers[0]?.id;
      const date = data.challenges[0].startDate;
      const start = date ? moment(date) : undefined;

      setChallenge({
        id: challenge.id,
        title: challenge.title,
        desc: challenge.description,
        starts: start,
      });
      setUserId(uId);
      const entries = data.challengeEntries.map(
        (entry: { taskId: string }) => entry.taskId
      );
      const processed: ProcessedTask[] = challenge.tasks.map(
        (task: TaskType) => {
          const now = moment().startOf("day");
          const over = now.isAfter(moment(task.dueTo));
          const completed = entries.includes(task.slug);
          return {
            title: task.title,
            desc: task.description,
            inactive: over,
            completed,
            url: task.slug,
            icon: mdiArrowRight,
          };
        }
      );

      setTasks(processed);
    }
  }, [data, error]);
  if (!user?.id) return null;
  return (
    <>
      <div className="challenge-bg is-hidden-touch is-hidden-desktop-only">
        <img src={bgPattern} alt="" />
      </div>
      <Switch>
        <Route
          path="/challenges/:id"
          exact
          render={() => (
            <ChallengeOverview
              tasks={tasks}
              challenge={challenge}
              challenges={challenges}
            />
          )}
        />
        <Route
          path="/challenges/:id/:taskId"
          exact
          render={() => (
            <TaskOrSummary
              tasks={tasks}
              challengeId={challenge?.id || 0}
              userId={userId}
              updateUploaded={updateUploaded}
            />
          )}
        />
      </Switch>
    </>
  );
}

type OverviewType = {
  tasks: MenuEl[];
  challenge?: ChallengeType;
  challenges: { name: string; icon: string; path: string; display: boolean }[];
};

function ChallengeOverview({ tasks, challenge, challenges }: OverviewType) {
  const [startsAt, setStartsAt] = useState<undefined | null | string>(null);
  useEffect(() => {
    const started = challenge?.starts;
    const diff = started?.diff(moment());
    if (!diff) setStartsAt(undefined);
    else if (diff < 0) setStartsAt("started");
    else {
      const date = moment(started).format("DD / MM / YYYY");
      setStartsAt(date);
    }
  }, [challenge]);

  if (!challenge || !tasks || startsAt === null) return null;
  return (
    <div className="columns m-0">
      <Helmet>
        <title>xPower — challenges</title>
        <meta name="description" content="xPower challenges page" />
      </Helmet>
      <Carousel elements={challenges} />
      {startsAt === undefined && <Waiting title={challenge.title} />}
      {startsAt !== "started" && startsAt !== undefined && (
        <Waiting date={startsAt} title={challenge.title} />
      )}
      {startsAt === "started" && (
        <div className="column is-7-widescreen is-9-desktop is-11-tablet is-offset-1">
          <h1 className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-black100 has-text-weight-bold mb-5 mt-3-mobile mt-5">
            {challenge.title}
          </h1>
          <AccordionText lines={3} text={challenge.desc}></AccordionText>
          <div className="mt-6 py-2">
            {tasks && <Menu columns={2} elements={tasks} />}
          </div>
        </div>
      )}
    </div>
  );
}

type TaskOrSummaryType = {
  tasks: MenuEl[];
  challengeId: number;
  userId: number;
  updateUploaded: (id: string) => void;
};

function TaskOrSummary({
  tasks,
  challengeId,
  userId,
  updateUploaded,
}: TaskOrSummaryType) {
  const { id, taskId }: { id: string; taskId: string } = useParams();
  const task = tasks.find((task) => task.url === taskId);
  if (!task) return <Redirect to={`/challenges/${id}`} />;
  if (task.completed)
    return <Summary task={task} challengeId={challengeId} userId={userId} />;
  return (
    <Task
      task={task}
      challengeId={challengeId}
      userId={userId}
      updateUploaded={updateUploaded}
    />
  );
}
